import { immer } from "zustand/middleware/immer";

export interface CommunityInboxSliceProps {
  inbox: {
    chats: any;
  };
}

export const createCommunityInboxSlice = immer<CommunityInboxSliceProps>(
  set => ({
    inbox: {
      chats: [],
      setChats: (chats: any) =>
        set(state => {
          state.inbox.chats = chats;
        }),
    },
  }),
);
