import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { MESSAGING_MODAL_PORTAL_ID } from "@circle-react/components/constants";
import { usePunditUserContext } from "@circle-react/contexts";

const checkIfMessagingModalIsOpen = () => {
  const element = document.getElementById(MESSAGING_MODAL_PORTAL_ID);
  return Boolean(element);
};

export const useDmsV3Enabled = () => {
  const { currentCommunitySettings, isLoading } = usePunditUserContext();
  const isMobileView = useSmOrMdOrLgScreenMediaQuery();
  const { dms_v3_enabled: isDmsV3Enabled } = currentCommunitySettings || {};
  const isMessagingInModalEnabled = Boolean(isDmsV3Enabled);

  return {
    isDmsV3Enabled: !isMobileView && isMessagingInModalEnabled,
    isDmsV3MobileViewEnabled: isMobileView && isMessagingInModalEnabled,
    isMessagingModalOpen: checkIfMessagingModalIsOpen(),
    isLoading,
  };
};
